<template>
  <div>
    <el-card style="width: 100%">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">键值信息筛选</span>
        </el-col>
      </el-row>
      <el-form :model="queryForm" label-width="80px" ref="queryFormRef">
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="键值内容" prop="text">
              <el-input
                v-model="queryForm.text"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="4">
            <el-button type="primary" style="width: 100%" @click="searchFn"
              >查询</el-button
            >
          </el-col>
          <el-col :span="4">
            <el-button type="primary" style="width: 100%" @click="resetFn"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <el-row
        class="title_row"
        align="middle"
        type="flex"
        style="margin-top: 25px"
      >
        <el-col :span="12">
          <span class="title_class">键值信息列表</span>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="primary" @click="showDialog(null)"
            >添加键值数据</el-button
          >
        </el-col>
      </el-row>
      <!-- 列表表格区域 -->
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="accountList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="显示值" prop="dictLabel"></el-table-column>
        <el-table-column label="传递值" prop="dictValue"></el-table-column>
        <el-table-column label="描述" prop="dictName"></el-table-column>
        <el-table-column label="排序" prop="dictSort"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.status === '0'"
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="showDialog(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              style="color: rgb(245, 108, 108)"
              @click="showDeleteAccountTip(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :title="title + '字典数据'"
      :visible.sync="visible"
      width="500px"
      @close="dialogCloseFn"
    >
      <el-form
        :model="editForm"
        label-width="100px"
        ref="editFormRef"
        :rules="editFormRules"
        style="width: 100%"
      >
        <el-form-item label="显示值" prop="dictLabel">
          <el-input
            v-model="editForm.dictLabel"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="传递值" prop="dictValue">
          <el-input
            v-model="editForm.dictValue"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="dictName">
          <el-input v-model="editForm.dictName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="dictSort">
          <el-input v-model="editForm.dictSort" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="图片地址" prop="dictUrl">
          <el-input v-model="editForm.dictUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" placeholder="请输入"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="修改权限" prop="status">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="1">可修改</el-radio>
            <el-radio :label="0">不可修改</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="saveFn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysDictDataList, addSysDictTypeValue, editSysDictTypeValue, deleteSysDictTypeValue } from '@/http/api'
export default {
  name: 'dictValue',
  props: {
    dictData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted () {
    this.dataAttrs = this.dictData
  },
  watch: {
    dictData (newV) {
      this.dataAttrs = JSON.parse(JSON.stringify(newV))
      this.getList()
    }
  },
  data () {
    return {
      dataAttrs: {},
      total: 0,
      accountList: [],
      visible: false,
      title: '',
      editType: 0,
      queryForm: {
        dictType: '',
        pageNum: 1,
        pageSize: 10,
        text: ''
      },
      editForm: {
        id: 0,
        dictType: '',
        dictLabel: '',
        dictValue: '',
        dictName: '',
        status: 1
      },
      editFormRules: {
        dictLabel: [{ required: true, message: '请输入', trigger: 'blur' }],
        dictValue: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getList () {
      this.queryForm.dictType = this.dataAttrs.dictType
      getSysDictDataList(this.queryForm).then(res => {
        this.accountList = res.data.list
        this.total = res.data.total
      })
    },
    searchFn () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    resetFn () {
      this.$refs.queryFormRef.resetFields()
      this.getList()
    },
    handleCurrentChange (e) {
      this.queryForm.pageNum = e
      this.getList()
    },
    handleSizeChange (e) {
      this.queryForm.pageSize = e
      this.getList()
    },
    showDialog (item) {
      this.visible = true
      this.title = item ? '修改' : '添加'
      this.editType = item ? 1 : 0
      if (item) {
        this.editForm = item
      } else {
        this.editForm = {
          dictLabel: '',
          dictName: '',
          dictValue: '',
          dictType: this.dictData.dictType,
          dictSort: '',
          id: 0,
          status: 1
        }
      }
    },
    showDeleteAccountTip (id) {
      this.$confirm('此操作将永久删除该键值对数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteSysDictType(id)
      })
    },
    dialogCloseFn () {
      this.visible = false
    },
    saveFn () {
      this.$refs.editFormRef.validate(valid => {
        if (!valid) return
        this.editForm.dictType = this.dataAttrs.dictType
        if (this.editType === 0) {
          addSysDictTypeValue(this.editForm).then(res => {
            this.$message.success(`${this.title}成功`)
            this.searchFn()
            this.visible = false
          })
        } else {
          editSysDictTypeValue(this.editForm).then(res => {
            this.$message.success(`${this.title}成功`)
            this.getList()
            this.visible = false
          })
        }
      })
    },
    // 删除键值对
    deleteSysDictType (deleteId) {
      deleteSysDictTypeValue({ id: deleteId }).then(res => {
        this.$message.success('删除成功')
        this.resetFn()
      })
    }
  }
}
</script>

<style scoped lang="less">
.label {
  color: #606266;
  font-size: 14px;
  font-weight: bold;
}
.value {
  font-size: 14px;
  margin-right: 60px;
}

.del-btn {
  color: rgb(245, 108, 108);
}
</style>
